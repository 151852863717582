// import * as Msdyn365 from '@msdyn365-commerce/core';
import { IFullOrgUnitAvailability } from '@msdyn365-commerce-modules/retail-actions';
import classnames from 'classnames';
import * as React from 'react';

import { IEveMapResources } from '../eve-map.props.autogenerated';

export interface IDealerSelectorEmptyMessageProps {
    resources: IEveMapResources;
}
export const DealerSelectorEmptyMessage: React.FC<IDealerSelectorEmptyMessageProps> = ({ resources }) => (
    <div className='ms-store-select__no-locations'>
        <p>{resources.emptyLocationsText}</p>
    </div>
);

export const DealerSelectorWaiting: React.FC = () => <div className={classnames('msc-waiting', 'ms-store-select__search-in-progress')} />;

export interface IDealerSelectorFoundLocationsMessage {
    resources: IEveMapResources;
    locations?: IFullOrgUnitAvailability[];
    searchRadius: number;
    preferredDistanceUnit: string;
    // isSeeAllStore: boolean;
}
export const DealerSelectorFoundLocationsMessage: React.FC<IDealerSelectorFoundLocationsMessage> = ({
    locations,
    searchRadius,
    resources,
    preferredDistanceUnit
    // isSeeAllStore
}) => {
    if (locations) {
        const dealerCountMessage =
            preferredDistanceUnit === resources.milesShortText ? resources.dealerCountMessage : resources.dealerCountMessageInKm;
        return (
            <div className='ms-store-select__found-locations'>
                {/* {isSeeAllStore
                    ? resources.storeAllCountMessage.replace('{count}', locations.length.toString())
                    : storeCountMessage.replace('{count}', locations.length.toString()).replace('{radius}', searchRadius.toString())} */}
                {dealerCountMessage.replace('{count}', locations.length.toString()).replace('{radius}', searchRadius.toString())}
            </div>
        );
    }

    return null;
};
